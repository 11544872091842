export interface IToken {
  id: string;
  active: boolean;
  apy: number;
  circulatingSupply: number;
  isBSPT: boolean;
  // For now, "userOwnsToken" is only relevant for BSPT
  // tokens because of filtering the available ones in
  // the StakingRow component.
  userOwnsToken: boolean;
  isLoadingPrice: boolean;
  isLoadingTokenSupply: boolean;
  name: string;
  price: number;
  valuation: number;
  symbol: string;
}

export class TokenInformation implements IToken {
  private _id: string;

  active: boolean;

  apy: number;

  circulatingSupply: number;

  isBSPT: boolean;

  userOwnsToken: boolean;

  isLoadingPrice: boolean;

  isLoadingTokenSupply: boolean;

  name: string;

  price: number;

  valuation: number;

  symbol: string;

  constructor(id: string, isBSPT: boolean) {
    this._id = id;
    this.active = true;
    this.apy = 0;
    this.circulatingSupply = 0;
    this.isBSPT = isBSPT;
    this.isLoadingPrice = false;
    this.isLoadingTokenSupply = false;
    this.name = '';
    this.price = 0;
    this.valuation = 0;
    this.symbol = '';
    this.userOwnsToken = false;
  }

  get id(): string {
    return this._id.toLowerCase();
  }

  get marketcap(): number {
    return this.circulatingSupply * this.price;
  }

  // A valuation of 0 means it isnt allowed for deposit
  get allowedForDeposit(): boolean {
    return this.valuation > 0;
  }

  // The valuation is the property valuation. We need to break it down by 100000 to get the fractional valuation per share.
  get fractionalValuation(): number {
    if (this.valuation > 0) return this.valuation / 100000;
    return 0;
  }
}
export interface ITokenState {
  tokens: Array<TokenInformation>;
  isLoadingBSPTTokens: boolean;
}

export interface IOwnedTokenData {
  data: {
    user: {
      tokens: Array<{ token: { id: string } }>;
    };
  };
}
