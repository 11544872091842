import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Web3Modal from 'web3modal';
import Web3 from 'web3';
import { config } from '@/config';

export function getWeb3Modal(): Web3Modal {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: config.infuraId,
      },
    },
    coinbasewallet: {
      package: CoinbaseWalletSDK,
      options: {
        appName: 'OceanPoint',
        infuraId: config.infuraId,
      },
    },
  };

  return new Web3Modal({
    cacheProvider: true,
    providerOptions,
  });
}

export async function getAddress(web3: Web3): Promise<string | null> {
  if (web3) {
    const accounts = await web3.eth.getAccounts();
    if (accounts.length >= 0) {
      const [account] = accounts;
      return account;
    }
  }

  return null;
}

export async function getChainId(web3: Web3): Promise<number | null> {
  if (web3) {
    const chainId = await web3.eth.getChainId();
    return chainId;
  }
  return null;
}
