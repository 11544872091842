/* eslint-disable import/prefer-default-export */
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { config } from '@/config';

export const blocksquareClient = new ApolloClient({
  uri: config.oceanpointSubgraph,
  cache: new InMemoryCache({ addTypename: false }),
});

export const uniswapClient = new ApolloClient({
  uri: config.uniswapSubgraph,
  cache: new InMemoryCache({ addTypename: false }),
});
