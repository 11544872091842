













































import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import { ISidebarLink } from '@/types';

export default Vue.extend({
  name: 'Header',
  data: () => ({
    topItems: [
      { title: 'Home', to: 'https://oceanpoint.fi/', external: true },
      { title: 'Docs', to: 'https://oceanpoint.gitbook.io/docs/', external: true },
      { title: 'FAQ', to: 'https://oceanpoint.gitbook.io/docs/faq', external: true },
      { title: 'Blocksquare', to: 'https://blocksquare.io/', external: true },
    ] as Array<ISidebarLink>,
    footerItems: [
      { title: 'Protocol disclaimer', to: 'https://oceanpoint.fi/#disclaimer', external: true },
      { title: 'Terms of service', to: 'https://oceanpoint.fi/#terms', external: true },
    ] as Array<ISidebarLink>,
  }),
  computed: {
    ...mapState('navigation', ['showSidebar']),
  },
  methods: {
    ...mapMutations('navigation', ['toggleSidebar']),
    onInputChange(newValue: boolean) {
      if (newValue !== this.showSidebar) this.toggleSidebar();
    },
  },
});
