import { ActionTree, GetterTree, MutationTree } from 'vuex';
import Web3 from 'web3';
import { getProviderInfo, INJECTED_PROVIDER_ID } from 'web3modal';
import { getAddress, getChainId, getWeb3Modal } from '@/lib/web3';
import { IWalletState } from '@/types';
import { config } from '@/config';

const initialState: IWalletState = {
  address: '',
  chainId: 0,
  isConnected: false,
  isConnectingWallet: false,
  provider: null,
  providerType: '',
  web3: null,
};

const getters: GetterTree<IWalletState, any> = {
  address: (state: IWalletState) => state.address,
  isNetworkUnsupported: (state: IWalletState) =>
    state.isConnected && state.chainId !== config.chainId,
  isInjectedProvider: (state: IWalletState) => state.providerType === INJECTED_PROVIDER_ID,
  web3: (state: IWalletState) => state.web3,
};

const mutations: MutationTree<IWalletState> = {
  setAddress(state: IWalletState, payload) {
    const { address } = payload;
    state.address = address;
  },
  setChainId(state: IWalletState, payload) {
    const { chainId } = payload;
    state.chainId = chainId;
  },
  setConnected(state: IWalletState, payload) {
    const { connected } = payload;
    state.isConnected = connected;
  },
  setConnectingWallet(state: IWalletState, payload) {
    const { connecting } = payload;
    state.isConnectingWallet = connecting;
  },
  setProvider(state: IWalletState, payload) {
    const { provider } = payload;
    state.provider = provider;
  },
  setProviderType(state: IWalletState, payload) {
    const { providerType } = payload;
    state.providerType = providerType;
  },
  setWeb3(state: IWalletState, payload) {
    const { web3 } = payload;
    state.web3 = web3;
  },
};

const actions = <ActionTree<IWalletState, any>>{
  async connectWallet({ commit, dispatch }) {
    commit('setConnectingWallet', { connecting: true });
    try {
      const provider = await getWeb3Modal().connect();
      const web3 = new Web3(provider);
      const address = await getAddress(web3);
      const chainId = await getChainId(web3);
      const providerType = getProviderInfo(provider).type;

      provider.on('accountsChanged', () => dispatch('updateAddress'));
      provider.on('chainChanged', () => dispatch('updateChainId'));
      provider.on('disconnect', () => dispatch('disconnectWallet'));

      commit('setWeb3', { web3 });
      commit('setAddress', { address });
      commit('setChainId', { chainId });
      commit('setConnected', { connected: true });
      commit('setProvider', { provider });
      commit('setProviderType', { providerType });
    } catch (err) {
      console.error(err);
    } finally {
      commit('setConnectingWallet', { connecting: false });
    }
  },
  async disconnectWallet({ commit }) {
    await getWeb3Modal().clearCachedProvider();
    commit('setAddress', { address: '' });
    commit('setConnected', { connected: false });
  },
  async updateAddress({ commit, dispatch, state }) {
    if (state.web3) {
      const address = await getAddress(state.web3);
      if (address) return commit('setAddress', { address });
      // Disconnect if not able to get address while web3 is set
      return dispatch('disconnectWallet');
    }
    return null;
  },
  async updateChainId({ commit, dispatch, state }) {
    if (state.web3) {
      const chainId = await getChainId(state.web3);
      if (chainId) return commit('setChainId', { chainId });
      // Disconnect if not able to get chainId while web3 is set
      return dispatch('disconnectWallet');
    }
    return null;
  },
};

export const wallet = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: initialState,
};
