import { MutationTree } from 'vuex';
import { INavigationState } from '@/types/navigation';

const initialState: INavigationState = {
  showSidebar: false,
};

const mutations: MutationTree<INavigationState> = {
  toggleSidebar(state: INavigationState) {
    state.showSidebar = !state.showSidebar;
  },
};

export const navigation = {
  namespaced: true,
  state: initialState,
  mutations,
};
