import Vue from 'vue';
import Vuex from 'vuex';

import { navigation } from '@/store/navigation';
import { pool } from '@/store/pool';
import { token } from '@/store/token';
import { wallet } from '@/store/wallet';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    navigation,
    pool,
    token,
    wallet,
  },
});
