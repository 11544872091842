/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_UNISWAP_TOKEN_QUERY = gql`
  query Token($tokenId: String) {
    token(id: $tokenId) {
      id
      symbol
      name
      decimals
      totalSupply
      tradeVolume
      tradeVolumeUSD
      untrackedVolumeUSD
      txCount
      totalLiquidity
      derivedETH
    }
  }
`;

export const GET_TOKEN_QUERY = gql`
  query Token($tokenId: String) {
    token(id: $tokenId) {
      id
      name
      symbol
      totalSupply
      valuation
    }
  }
`;

export const GET_ETH_PRICE_QUERY = gql`
  query ETH_PRICE {
    bundles(where: { id: 1 }) {
      ethPrice
    }
  }
`;

export const GET_BSPT_TOKENS_FOR_OCEANPOINT_QUERY = gql`
  query BSPT_TOKENS_FOR_OCEANPOINT {
    tokens(where: { valuation_gt: 0 }) {
      id
      valuation
      name
      symbol
      totalSupply
    }
  }
`;
