import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getPoolConfig } from '@/utils/pool';
import { PoolConfig } from './poolConfig';

dayjs.extend(utc);

export interface IPoolAmounts {
  currentAmount: number;
  ratio: number;
  sAmount: number;
  valuePerBSPT: number;
  tokenId: string;
}

export interface IPoolDeposit {
  sAmount: number;
  lockedUntil: number;
  valuePerBSPT: number;
  tokenId: string;
}

export interface IPoolStat {
  amountStaked: number;
  currentAmount: number;
  date: string;
  id: string;
  ratio: number;
  sAmount: number;
  timestamp: number;
  totalRewards: number;
}

export interface IBSPTPoolStat {
  id: string;
  timestamp: number;
  date: string;
  totalValue: number;
  totalRewards: number;
}

export interface IPoolInformation {
  amounts: Array<IPoolAmounts>;
  id: string;
  isLoadingBalance: boolean;
  isLoadingStats: boolean;
  isLoadingUsdPrice: boolean;
  isLoadingUserBalance: boolean;
  hasLoadedUserBalance: boolean;
  stats: Array<IPoolStat>;
  bsptStats: Array<IBSPTPoolStat>;
  userDeposits: Array<IPoolDeposit>;
  usdPrice: number;
}

export class PoolInformation implements IPoolInformation {
  private _id: string;

  amounts: Array<IPoolAmounts>;

  config: PoolConfig;

  isLoadingBalance: boolean;

  isLoadingStats: boolean;

  isLoadingUsdPrice: boolean;

  isLoadingUserBalance: boolean;

  hasLoadedUserBalance: boolean;

  stats: Array<IPoolStat>;

  bsptStats: Array<IBSPTPoolStat>;

  userDeposits: Array<IPoolDeposit>;

  usdPrice: number;

  constructor(id: string) {
    this._id = id.toLowerCase();
    this.amounts = new Array<IPoolAmounts>();
    this.config = getPoolConfig(this._id);
    this.isLoadingBalance = false;
    this.isLoadingStats = false;
    this.isLoadingUsdPrice = false;
    this.isLoadingUserBalance = false;
    this.hasLoadedUserBalance = false;
    this.stats = new Array<IPoolStat>();
    this.bsptStats = new Array<IBSPTPoolStat>();
    this.userDeposits = new Array<IPoolDeposit>();
    this.usdPrice = 0;
  }

  get id(): string {
    return this._id;
  }

  get isActive(): boolean {
    return !this.config.stopped;
  }

  // This is only used for pools that have a single asset for staking like the governance pool.
  get userAmount(): number {
    if (this.config.isBSPTPool) return 0;
    return this.userSAmount * (this.amounts[0]?.ratio ?? 0);
  }

  get userSAmount(): number {
    return this.userDeposits[0]?.sAmount ?? 0;
  }

  get userLockedUntil(): number {
    if (this.config.isBSPTPool) return 0;
    return this.userDeposits[0]?.lockedUntil ?? 0;
  }

  // This is only used for the BSPT pool
  get bsptDepositPropertyCount(): number {
    return this.userDeposits.length;
  }

  get userPoolShare(): number {
    // If pool is not of type BSPT then use a simple calulation
    if (!this.config.isBSPTPool) return (this.userAmount / this.currentAmount()) * 100;

    return (this.userValue / this.totalValueLocked) * 100;
  }

  get userValue(): number {
    // If pool is not of type BSPT then use a simple calulation
    if (!this.config.isBSPTPool) return this.userAmount * this.usdPrice;

    let totalUserValuation = 0;
    this.userDeposits.forEach((deposit) => {
      const valuation = deposit.sAmount * deposit.valuePerBSPT;
      totalUserValuation += valuation;
    });

    return totalUserValuation;
  }

  get totalValueLocked(): number {
    // If pool is not of type BSPT then use a simple calulation
    if (!this.config.isBSPTPool) return this.currentAmount() * this.usdPrice;

    let totalAmount = 0;
    this.amounts.forEach((amount) => {
      totalAmount += amount.valuePerBSPT * amount.currentAmount;
    });

    return totalAmount;
  }

  get lastReward(): number {
    if (this.stats.length > 1) {
      const tempStatsArray = this.stats.slice(2);
      return tempStatsArray[0].totalRewards - tempStatsArray[1].totalRewards;
    }
    if (this.stats.length === 1) {
      return this.stats[0].totalRewards;
    }
    return 0;
  }

  get apy10(): number {
    const tempDate = dayjs().utc().startOf('day').subtract(10, 'day');
    const tempStats = this.stats
      .filter((stat) => {
        const date = dayjs.unix(stat.timestamp).utc();
        return date >= tempDate;
      })
      .map((stat) => stat.totalRewards);

    if (tempStats.length === 0) return 0;

    // Add an empty stats object for the apy calulation if only one datapoint is available
    if (tempStats.length === 1) {
      tempStats.push(0);
    }

    const tempRewards10Days = tempStats[0] - tempStats[tempStats.length - 1];

    if (tempRewards10Days === 0) return 0;

    const dailyReward = tempRewards10Days / 10;
    const yearlyReward = dailyReward * 365;
    const apy = (yearlyReward / this.currentAmount()) * 100;
    return apy;
  }

  get bsptApy10(): number {
    const tempDate = dayjs().utc().startOf('day').subtract(10, 'day');
    const tempStats = this.bsptStats
      .filter((stat) => {
        const date = dayjs.unix(stat.timestamp).utc();
        return date >= tempDate;
      })
      .map((stat) => stat.totalRewards);

    if (tempStats.length === 0) return 0;

    // Add an empty stats object for the apy calulation if only one datapoint is available
    if (tempStats.length === 1) {
      tempStats.push(0);
    }

    const tempBSTRewards10Days = tempStats[0] - tempStats[tempStats.length - 1];
    const dailyBSTReward = tempBSTRewards10Days / 10;
    const yearlyBSTReward = dailyBSTReward * 365;

    const yearlyReward = yearlyBSTReward * this.usdPrice;
    const apy = (yearlyReward / this.totalValueLocked) * 100;
    return apy;
  }

  currentAmount(): number {
    if (this.config.isBSPTPool) return 0;
    return this.amounts[0]?.currentAmount ?? 0;
  }

  // BSPT methods
  hasUserBSPTDeposit(tokenId: string): boolean {
    return this.userDeposits.some((deposit) => deposit.tokenId === tokenId);
  }
}

export interface IPoolState {
  pools: Array<PoolInformation>;
}
