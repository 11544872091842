
























import Vue from 'vue';

export default Vue.extend({
  name: 'ProgressOverlay',
  props: {
    visible: {
      type: Boolean,
      default: true,
    },

    loadingText: {
      type: String,
      default: 'Data is loading. Please wait.',
    },

    /**
     * The positive CSS size value of how much is ProgressOverlay larger than its relative parent on each side.
     * Makes nice flow of the blurred content behind the backdrop.
     */
    bleed: {
      type: String,
      default: '5px',
    },

    /**
     * The positive CSS size value of the backdrop blur intensity.
     */
    blur: {
      type: String,
      default: '4px',
    },
  },
  data: () => ({}),
  computed: {
    cssProps() {
      return {
        '--backdrop-bleed': `-${this.bleed}`,
        '--backdrop-blur': `${this.blur}`,
      };
    },
  },
});
