import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { ITokenState, TokenInformation, IOwnedTokenData } from '@/types/token';
import { blocksquareClient, uniswapClient } from '@/query/client';
import {
  GET_BSPT_TOKENS_FOR_OCEANPOINT_QUERY,
  GET_ETH_PRICE_QUERY,
  GET_TOKEN_QUERY,
  GET_UNISWAP_TOKEN_QUERY,
} from '@/query/token';
import { fromWei } from '@/utils';
import { config } from '@/config';
import { GET_USER_TOKENS } from '@/query/user';

const createEmptyTokenInformation = (id: string, isBSPT = true) => new TokenInformation(id, isBSPT);

const initialState: ITokenState = {
  tokens: [createEmptyTokenInformation(config.bstTokenContractAddress, false)],
  isLoadingBSPTTokens: false,
};

const getters: GetterTree<ITokenState, any> = {
  bsptTokens: (state: ITokenState): Array<TokenInformation> =>
    state.tokens
      .filter((tempToken) => tempToken.isBSPT === true)
      // Sort bspt tokens based on token symbol in ascending order
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => (a.symbol < b.symbol ? -1 : a.symbol > b.symbol ? 1 : 0)),
  token: (state: ITokenState) => (id: string): TokenInformation =>
    state.tokens.find((tempToken) => tempToken.id === id) || createEmptyTokenInformation(id),
};

const mutations: MutationTree<ITokenState> = {
  setCirculatingSupply(state: ITokenState, payload) {
    const { tokenId, circulatingSupply } = payload;
    const tokenIndex = state.tokens.findIndex((tempToken) => tempToken.id === tokenId);
    const token =
      tokenIndex === -1 ? createEmptyTokenInformation(tokenId) : state.tokens[tokenIndex];
    token.circulatingSupply = circulatingSupply;
    if (tokenIndex === -1) {
      state.tokens.push(token);
    }
  },
  setIsLoadingPrice(state: ITokenState, payload) {
    const { tokenId, loading } = payload;
    const tokenIndex = state.tokens.findIndex((tempToken) => tempToken.id === tokenId);
    const token =
      tokenIndex === -1 ? createEmptyTokenInformation(tokenId) : state.tokens[tokenIndex];
    token.isLoadingPrice = loading;
    if (tokenIndex === -1) {
      state.tokens.push(token);
    }
  },
  setIsLoadingTokenSupply(state: ITokenState, payload) {
    const { tokenId, loading } = payload;
    const tokenIndex = state.tokens.findIndex((tempToken) => tempToken.id === tokenId);
    const token =
      tokenIndex === -1 ? createEmptyTokenInformation(tokenId) : state.tokens[tokenIndex];
    token.isLoadingTokenSupply = loading;
    if (tokenIndex === -1) {
      state.tokens.push(token);
    }
  },
  setName(state: ITokenState, payload) {
    const { tokenId, name } = payload;
    const tokenIndex = state.tokens.findIndex((tempToken) => tempToken.id === tokenId);
    const token =
      tokenIndex === -1 ? createEmptyTokenInformation(tokenId) : state.tokens[tokenIndex];
    token.name = name;
    if (tokenIndex === -1) {
      state.tokens.push(token);
    }
  },
  setPrice(state: ITokenState, payload) {
    const { tokenId, price } = payload;
    const tokenIndex = state.tokens.findIndex((tempToken) => tempToken.id === tokenId);
    const token =
      tokenIndex === -1 ? createEmptyTokenInformation(tokenId) : state.tokens[tokenIndex];
    token.price = price;
    if (tokenIndex === -1) {
      state.tokens.push(token);
    }
  },
  setSymbol(state: ITokenState, payload) {
    const { tokenId, symbol } = payload;
    const tokenIndex = state.tokens.findIndex((tempToken) => tempToken.id === tokenId);
    const token =
      tokenIndex === -1 ? createEmptyTokenInformation(tokenId) : state.tokens[tokenIndex];
    token.symbol = symbol;
    if (tokenIndex === -1) {
      state.tokens.push(token);
    }
  },
  setValuation(state: ITokenState, payload) {
    const { tokenId, valuation } = payload;
    const tokenIndex = state.tokens.findIndex((tempToken) => tempToken.id === tokenId);
    const token =
      tokenIndex === -1 ? createEmptyTokenInformation(tokenId) : state.tokens[tokenIndex];
    token.valuation = valuation;
    if (tokenIndex === -1) {
      state.tokens.push(token);
    }
  },
  setIsLoadingBSPTTokens(state: ITokenState, payload) {
    const { loading } = payload;
    state.isLoadingBSPTTokens = loading;
  },
  setBSPTTokenOwnership(state: ITokenState, payload) {
    const { ownedTokens } = payload;

    // Due to the fact that this mutation is called even on wallet reconnect
    // we must reset the token ownership for all tokens that used to be available
    // for the previous wallet if it existed
    state.tokens.forEach((token) => {
      const _token = token;

      if (_token.userOwnsToken) {
        _token.userOwnsToken = false;
      }
    });

    ownedTokens.forEach((ownedToken: string) => {
      const tokenIndex = state.tokens.findIndex((tempToken) => tempToken.id === ownedToken);
      // If this token is available for depositing...
      if (tokenIndex >= 0) {
        const token = state.tokens[tokenIndex];
        token.userOwnsToken = true;
      }
    });
  },
};

const actions: ActionTree<ITokenState, any> = {
  // This action aggregates information on which tokens the user has in their wallet
  // and the ones they already have deposited in order to display only available
  // properties in the "select" dropdown menu for the Asset pool staking row
  async getBSPTTokenOwnership({ commit }, payload) {
    // Deposited tokens are passed in from the StakingRow component watcher
    const { depositedTokens, wallet } = payload;

    const ownedTokenData = <IOwnedTokenData>await blocksquareClient.query({
      query: GET_USER_TOKENS,
      variables: {
        userId: wallet.toLowerCase(),
      },
    });

    const ownedTokenIds: Array<string> =
      ownedTokenData?.data?.user?.tokens?.map(
        (tokenData: { token: { id: string } }) => tokenData.token.id
      ) ?? [];

    let ownedTokens = depositedTokens.concat(ownedTokenIds);

    // Remove any possible duplicates
    ownedTokens = [...new Set(ownedTokens)];

    commit('setBSPTTokenOwnership', { ownedTokens });
  },
  async updateTokenDetails({ commit }, payload) {
    const { tokenId } = payload;
    commit('setIsLoadingTokenSupply', { tokenId, loading: true });
    const tokenDetails = await blocksquareClient.query({
      query: GET_TOKEN_QUERY,
      variables: {
        tokenId,
      },
    });

    const {
      data: {
        token: { name, symbol, totalSupply: totalSupplyBN, valuation: valuationBN },
      },
    } = tokenDetails;
    // TotalSupply = CirculatingSupply for BST
    const totalSupply = fromWei(totalSupplyBN);
    const valuation = fromWei(valuationBN);
    commit('setCirculatingSupply', { tokenId, circulatingSupply: totalSupply });
    commit('setName', { tokenId, name });
    commit('setSymbol', { tokenId, symbol });
    commit('setValuation', { tokenId, valuation });
    commit('setIsLoadingTokenSupply', { tokenId, loading: false });
  },

  async updateTokenPrice({ commit }, payload) {
    const { tokenId, poolId } = payload;
    commit('setIsLoadingPrice', { tokenId, loading: true });
    commit('pool/setIsLoadingPoolPrice', { loading: true, poolId }, { root: true });
    const ethPriceData = await uniswapClient.query({ query: GET_ETH_PRICE_QUERY });
    const {
      data: { bundles },
    } = ethPriceData;
    const { ethPrice } = bundles[0];

    const bstTokenData = await uniswapClient.query({
      query: GET_UNISWAP_TOKEN_QUERY,
      variables: {
        // Mainnet BST address needed here to query the price via Uniswap
        tokenId: config.bstTokenMainnetAddress.toLowerCase(),
      },
    });
    const {
      data: {
        token: { derivedETH: bstTokenPriceInETH },
      },
    } = bstTokenData;

    const price = bstTokenPriceInETH * ethPrice;
    commit('setPrice', { tokenId, price });
    commit('pool/setPoolPrice', { price, poolId }, { root: true });
    commit('setIsLoadingPrice', { tokenId, loading: false });
    commit('pool/setIsLoadingPoolPrice', { loading: false, poolId }, { root: true });
  },

  async updateBSPTTokens({ commit }) {
    commit('setIsLoadingBSPTTokens', { loading: true });
    const tokenDetails = await blocksquareClient.query({
      query: GET_BSPT_TOKENS_FOR_OCEANPOINT_QUERY,
    });

    const {
      data: { tokens },
    } = tokenDetails;

    tokens.forEach((token: any) => {
      const {
        id: tokenId,
        name,
        symbol,
        totalSupply: totalSupplyBN,
        valuation: valuationBN,
      } = token;
      // TotalSupply = CirculatingSupply of BSPT
      const totalSupply = fromWei(totalSupplyBN);
      const valuation = fromWei(valuationBN);
      commit('setCirculatingSupply', { tokenId, circulatingSupply: totalSupply });
      commit('setName', { tokenId, name });
      commit('setSymbol', { tokenId, symbol });
      commit('setValuation', { tokenId, valuation });
    });
    commit('setIsLoadingBSPTTokens', { loading: false });
  },
};

export const token = {
  namespaced: true,
  state: initialState,
  actions,
  getters,
  mutations,
};
