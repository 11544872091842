




































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { config } from '@/config';
import { PoolInformation, TokenInformation } from '@/types';
import SectionHeading from '../common/typography/SectionHeading.vue';

const InfoBlock = () =>
  import(/* webpackChunkName: "info-block" */ '@/components/common/cards/InfoBlock.vue');

export default Vue.extend({
  name: 'MarketStats',
  components: { SectionHeading, InfoBlock },
  data: () => ({
    currency: '$',
  }),
  mounted() {
    this.updateTokenDetails({ tokenId: config.bstTokenContractAddress });
    this.updateTokenPrice({
      tokenId: config.bstTokenContractAddress,
      poolId: config.stakingContractAddress,
    });
    this.updateTokenPrice({
      tokenId: config.bstTokenContractAddress,
      poolId: config.bsptPoolAddress,
    });
  },
  computed: {
    ...mapGetters('token', ['token']),
    ...mapGetters('pool', ['pool']),
    bstToken(): TokenInformation {
      return this.token(config.bstTokenContractAddress);
    },
    governancePool(): PoolInformation {
      return this.pool(config.stakingContractAddress);
    },
  },
  methods: {
    ...mapActions('token', ['updateTokenPrice', 'updateTokenDetails']),
  },
});
