/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_POOL_BALANCE_QUERY = gql`
  query GetPoolBalances($id: String!) {
    stakingPool(id: $id) {
      poolAmounts {
        id
        currentAmount
        ratio
        sAmount
        valuePerBSPT
        token {
          id
        }
      }
    }
  }
`;

export const GET_POOL_USER_BALANCE_QUERY = gql`
  query GetPoolUserBalances($id: String!) {
    stakingPoolUser(id: $id) {
      deposits {
        id
        token {
          id
        }
        lockedUntil
        sAmount
        valuePerBSPT
      }
    }
  }
`;

export const GET_POOL_STATS_QUERY = gql`
  query GetPoolStats($id: String!) {
    stakingPool(id: $id) {
      id
      stats(first: 30, orderBy: date, orderDirection: desc) {
        id
        amountStaked
        currentAmount
        date
        ratio
        sAmount
        totalRewards
      }
    }
  }
`;

export const GET_BSPT_POOL_STATS_QUERY = gql`
  query GetPoolStats($id: String!) {
    stakingPool(id: $id) {
      id
      bsptStats(first: 30, orderBy: date, orderDirection: desc) {
        id
        totalValue
        totalRewards
        date
      }
    }
  }
`;
