import { gql } from '@apollo/client';

export const GET_USER_TOKENS = gql`
  query UserToken($userId: String) {
    user(id: $userId) {
      tokens {
        id
        amount
        token {
          id
          name
          symbol
          valuation
        }
      }
    }
  }
`;
