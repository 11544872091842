








import Vue from 'vue';

export default Vue.extend({
  name: 'Footer',

  data: () => ({
    currentYear: new Date().getFullYear(),
  }),
});
