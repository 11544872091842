




































































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import SectionHeading from '../common/typography/SectionHeading.vue';
import FullSizeCard from '../common/cards/FullSizeCard.vue';
import { PoolInformation } from '@/types';

const InfoBlock = () =>
  import(/* webpackChunkName: "info-block" */ '@/components/common/cards/InfoBlock.vue');

export default Vue.extend({
  name: 'PersonalStats',
  components: {
    SectionHeading,
    FullSizeCard,
    InfoBlock,
  },
  data: () => ({
    currency: '$',
  }),
  computed: {
    ...mapState('pool', ['pools']),
    ...mapGetters('pool', ['portfolioBalance', 'isLoadingPortfolio']),

    totalTokenAmount() {
      return this.pools.reduce(
        (totalAmount: number, pool: PoolInformation) => totalAmount + pool.userAmount,
        0
      );
    },
  },
});
