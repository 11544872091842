


































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Footer from './components/navigation/Footer.vue';
import Header from './components/navigation/Header.vue';
import Sidebar from './components/navigation/Sidebar.vue';
import { getWeb3Modal } from './lib/web3';

const WrongNetworkDialog = () =>
  import(/* webpackChunkName: "wrong-network-dialog" */ '@/components/web3/WrongNetworkDialog.vue');

export default Vue.extend({
  name: 'App',
  components: {
    Footer,
    Header,
    Sidebar,
    WrongNetworkDialog,
  },
  data: () => ({}),
  computed: {
    ...mapGetters('wallet', ['isNetworkUnsupported']),
  },
  mounted() {
    // Reconnect wallet if present in cache
    const web3Modal = getWeb3Modal();
    if (web3Modal.cachedProvider) this.connectWallet();
  },
  methods: {
    ...mapActions('wallet', ['connectWallet']),
  },
});

Vue.mixin({
  methods: {
    getLocalizedAmount: (number: number, prefix?: string, suffix?: string): string => {
      let toLocaleStringsParams = {};
      if (number % 1 !== 0) {
        toLocaleStringsParams = { minimumFractionDigits: 2 };
      }
      return `${prefix || ''}${
        number ? number.toLocaleString(undefined, toLocaleStringsParams) : ' - '
      }${suffix || ''}`;
    },
  },
});
