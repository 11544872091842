import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { config } from '@/config';
import {
  PoolInformation,
  IPoolStat,
  IPoolState,
  IPoolDeposit,
  IPoolAmounts,
  IBSPTPoolStat,
} from '@/types';
import { blocksquareClient } from '@/query/client';
import { fromWei } from '@/utils';
import {
  GET_BSPT_POOL_STATS_QUERY,
  GET_POOL_BALANCE_QUERY,
  GET_POOL_STATS_QUERY,
  GET_POOL_USER_BALANCE_QUERY,
} from '@/query/pool';

const initialState: IPoolState = {
  pools: [
    // Create pool informations
    new PoolInformation(config.stakingContractAddress),
    new PoolInformation(config.bsptPoolAddress),
    new PoolInformation(config.bstEthPoolAddress),
    new PoolInformation(config.bstDaiPoolAddress),
    new PoolInformation(config.pointPoolAddress),
    new PoolInformation(config.pointDaiPoolAddress),
  ],
};

const getters: GetterTree<IPoolState, any> = {
  pool: (state: IPoolState) => (id: string): PoolInformation =>
    state.pools.find((tempPool) => tempPool.id === id) || new PoolInformation(''),
  portfolioBalance: (state: IPoolState): number =>
    state.pools.reduce((sum, pool) => sum + (pool.userValue ? pool.userValue : 0), 0),
  isLoadingPortfolio: (state: IPoolState): boolean =>
    state.pools.filter((pool) => pool.isLoadingUserBalance || pool.isLoadingUsdPrice).length >= 1,
  totalValueLocked: (state: IPoolState): number =>
    state.pools.reduce((sum, pool) => sum + (pool.totalValueLocked ? pool.totalValueLocked : 0), 0),
  isLoadingToalValueLocked: (state: IPoolState): boolean =>
    state.pools.filter((pool) => pool.isLoadingBalance || pool.isLoadingUsdPrice).length >= 1,
};

const mutations: MutationTree<IPoolState> = {
  setIsLoadingBalance(state: IPoolState, payload) {
    const { poolId, loading } = payload;
    const poolIndex = state.pools.findIndex((tempPool) => tempPool.id === poolId);
    const pool = state.pools[poolIndex];
    pool.isLoadingBalance = loading;
  },
  setPoolBalances(state: IPoolState, payload) {
    const { poolId, currentAmount, ratio, sAmount, valuePerBSPT, tokenId } = payload;
    const poolIndex = state.pools.findIndex((tempPool) => tempPool.id === poolId);
    const pool = state.pools[poolIndex];
    const amountIndex = pool.amounts.findIndex((tempAmount) => tempAmount.tokenId === tokenId);
    const poolAmount = pool.amounts[amountIndex] ?? ({} as IPoolAmounts);
    poolAmount.tokenId = tokenId;
    poolAmount.currentAmount = currentAmount;
    poolAmount.sAmount = sAmount;
    poolAmount.valuePerBSPT = valuePerBSPT;
    poolAmount.ratio = ratio;
    if (amountIndex === -1) pool.amounts.push(poolAmount);
  },
  setIsLoadingStats(state: IPoolState, payload) {
    const { poolId, loading } = payload;
    const poolIndex = state.pools.findIndex((tempPool) => tempPool.id === poolId);
    const pool = state.pools[poolIndex];
    pool.isLoadingStats = loading;
  },
  setPoolStats(state: IPoolState, payload) {
    const { poolId, stats } = payload;

    const poolIndex = state.pools.findIndex((tempPool) => tempPool.id === poolId);
    const pool = state.pools[poolIndex];
    pool.stats = stats;
  },
  setIsLoadingUserBalance(state: IPoolState, payload) {
    const { poolId, loading } = payload;

    const poolIndex = state.pools.findIndex((tempPool) => tempPool.id === poolId);
    const pool = state.pools[poolIndex];
    pool.isLoadingUserBalance = loading;
  },
  // This enables us to determine if we are ready for accumulating
  // all available tokens in the StakingRow component
  setHasLoadedUserBalance(state: IPoolState, payload: { poolId: string; setting: boolean }) {
    const { poolId, setting } = payload;

    const poolIndex = state.pools.findIndex((tempPool) => tempPool.id === poolId);
    const pool = state.pools[poolIndex];
    pool.hasLoadedUserBalance = setting;
  },
  setUserPoolBalances(state: IPoolState, payload) {
    const { poolId, sAmount, lockedUntil, tokenId, valuePerBSPT } = payload;

    const poolIndex = state.pools.findIndex((tempPool) => tempPool.id === poolId);
    const pool = state.pools[poolIndex];
    const depositIndex = pool.userDeposits.findIndex((tempPool) => tempPool.tokenId === tokenId);
    const deposit = pool.userDeposits[depositIndex] ?? ({} as IPoolDeposit);
    deposit.tokenId = tokenId;
    deposit.lockedUntil = lockedUntil;
    deposit.sAmount = sAmount;
    deposit.tokenId = tokenId;
    if (valuePerBSPT) deposit.valuePerBSPT = valuePerBSPT;
    if (depositIndex === -1) pool.userDeposits.push(deposit);
  },
  resetUserPoolBalances(state: IPoolState, payload) {
    const { poolId } = payload;

    const poolIndex = state.pools.findIndex((tempPool) => tempPool.id === poolId);
    const pool = state.pools[poolIndex];

    pool.userDeposits = [];
  },
  setIsLoadingPoolPrice(state: IPoolState, payload) {
    const { loading, poolId } = payload;
    const poolIndex = state.pools.findIndex((tempPool) => tempPool.id === poolId);
    const pool = state.pools[poolIndex];
    pool.isLoadingUsdPrice = loading;
  },
  setPoolPrice(state: IPoolState, payload) {
    const { price, poolId } = payload;
    const poolIndex = state.pools.findIndex((tempPool) => tempPool.id === poolId);
    const pool = state.pools[poolIndex];
    pool.usdPrice = price;
  },
  setBSPTPoolStats(state: IPoolState, payload) {
    const { poolId, stats } = payload;

    const poolIndex = state.pools.findIndex((tempPool) => tempPool.id === poolId);
    const pool = state.pools[poolIndex];
    pool.bsptStats = stats;
  },
};

const actions: ActionTree<IPoolState, any> = {
  async updatePoolBalance({ commit }, payload) {
    const { poolId } = payload;
    commit('setIsLoadingBalance', { poolId, loading: true });
    const poolData = await blocksquareClient.query({
      query: GET_POOL_BALANCE_QUERY,
      variables: {
        id: poolId,
      },
    });

    const {
      data: { stakingPool },
    } = poolData;

    if (stakingPool) {
      const { poolAmounts } = stakingPool;
      poolAmounts.forEach((poolAmount: any) => {
        const {
          currentAmount: cAmountBN,
          ratio,
          sAmount: sAmountBN,
          valuePerBSPT: valuePerBSPTBN,
          token: { id: tokenId },
        } = poolAmount;

        const currentAmount = fromWei(cAmountBN);
        const sAmount = fromWei(sAmountBN);
        const valuePerBSPT = fromWei(valuePerBSPTBN);
        commit('setPoolBalances', { poolId, currentAmount, ratio, sAmount, valuePerBSPT, tokenId });
      });
    }

    commit('setIsLoadingBalance', { poolId, loading: false });
  },
  async updatePoolStats({ commit }, payload) {
    const { poolId } = payload;
    commit('setIsLoadingStats', { poolId, loading: true });
    const poolData = await blocksquareClient.query({
      query: GET_POOL_STATS_QUERY,
      variables: {
        id: poolId,
      },
    });

    const {
      data: { stakingPool },
    } = poolData;

    if (stakingPool && stakingPool.stats) {
      const { stats } = stakingPool;
      const formattedStats = new Array<IPoolStat>();

      stats.forEach((stat: any) => {
        const {
          amountStaked: amountStakedBN,
          currentAmount: currentAmountBN,
          date: timestamp,
          ratio,
          sAmount: sAmountBN,
          totalRewards: totalRewardsAmountBN,
        } = stat;
        const amountStaked = fromWei(amountStakedBN);
        const currentAmount = fromWei(currentAmountBN);
        const sAmount = fromWei(sAmountBN);
        const totalRewards = fromWei(totalRewardsAmountBN);

        const date = new Date(timestamp * 1000);
        const dateFormatted = date.toISOString().split('T')[0];

        formattedStats.push({
          amountStaked,
          currentAmount,
          date: dateFormatted,
          id: stat.id,
          ratio,
          sAmount,
          timestamp,
          totalRewards,
        });
      });

      commit('setPoolStats', { poolId, stats: formattedStats });
    }
    commit('setIsLoadingStats', { poolId, loading: false });
  },
  async updateBSPTPoolStats({ commit }, payload) {
    const { poolId } = payload;
    commit('setIsLoadingStats', { poolId, loading: true });
    const poolData = await blocksquareClient.query({
      query: GET_BSPT_POOL_STATS_QUERY,
      variables: {
        id: poolId,
      },
    });

    const {
      data: { stakingPool },
    } = poolData;

    if (stakingPool && stakingPool.bsptStats) {
      const { bsptStats } = stakingPool;
      const formattedStats = new Array<IBSPTPoolStat>();

      bsptStats.forEach((stat: any) => {
        const {
          date: timestamp,
          totalValue: totalValueBN,
          totalRewards: totalRewardsBN,
          id,
        } = stat;
        const totalValue = fromWei(totalValueBN);
        const totalRewards = fromWei(totalRewardsBN);
        const date = new Date(timestamp * 1000);

        const dateFormatted = date.toISOString().split('T')[0];

        formattedStats.push({
          date: dateFormatted,
          id,
          timestamp,
          totalValue,
          totalRewards,
        });
      });

      commit('setBSPTPoolStats', { poolId, stats: formattedStats });
    }
    commit('setIsLoadingStats', { poolId, loading: false });
  },
  async updateUserPoolBalance({ commit }, payload) {
    const { poolId, userId } = payload;
    const userPoolId = `${poolId}-${userId}`;
    commit('setIsLoadingUserBalance', { poolId, loading: true });
    // Before setting new possible values like when a wallet reconnect occurs,
    // we must reset the old ones from the previous wallet
    commit('resetUserPoolBalances', { poolId });
    commit('setHasLoadedUserBalance', { poolId, setting: false });
    const poolData = await blocksquareClient.query({
      query: GET_POOL_USER_BALANCE_QUERY,
      variables: {
        id: userPoolId,
      },
    });

    if (poolData.data.stakingPoolUser) {
      const {
        data: {
          stakingPoolUser: { deposits },
        },
      } = poolData;

      deposits.forEach((deposit: any) => {
        const {
          sAmount: sAmountBN,
          lockedUntil: lockedUntilTemp,
          token: { id: tokenId },
          valuePerBSPT: valuePerBSPTBN,
        } = deposit;
        const sAmount = fromWei(sAmountBN);
        const lockedUntil = parseInt(lockedUntilTemp, 10);
        const valuePerBSPT = fromWei(valuePerBSPTBN);

        commit('setUserPoolBalances', { poolId, sAmount, lockedUntil, valuePerBSPT, tokenId });
      });
    }

    commit('setIsLoadingUserBalance', { poolId, loading: false });
    commit('setHasLoadedUserBalance', { poolId, setting: true });
  },
};

export const pool = {
  namespaced: true,
  state: initialState,
  actions,
  getters,
  mutations,
};
