






































































































import Vue from 'vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import { displayAddress } from '@/utils/format';

export default Vue.extend({
  name: 'Header',
  computed: {
    ...mapState('wallet', ['address', 'isConnected', 'isConnectingWallet']),
  },
  filters: {
    formatAddress(address: string): string {
      return displayAddress(address);
    },
  },
  methods: {
    ...mapMutations('navigation', ['toggleSidebar']),
    ...mapActions('wallet', ['connectWallet', 'disconnectWallet']),
  },
});
