import { config } from './config';
import { IPoolConfig } from '@/types/pool/poolConfig';

const POOL_DETAILS = [
  {
    id: config.stakingContractAddress,
    asset1Address: config.bstTokenContractAddress,
    asset1Name: 'BST',
    asset2Address: config.stakingContractAddress,
    asset2Name: 'sBST',
    color: '#00c0aa',
    name: 'BST',
    isGovernancePool: true,
    poolType: 'oceanpoint',
    portfolioName: 'Governance pool',
    stopped: false,
  },
  {
    id: config.bsptPoolAddress,
    asset1Address: '',
    asset1Name: 'BSPT',
    asset2Address: config.bsptPoolAddress,
    asset2Name: '',
    color: '#60d2c2',
    isGovernancePool: false,
    isBSPTPool: true,
    name: 'BSPT',
    poolType: 'oceanpoint',
    portfolioName: 'Asset pool',
    info:
      'Fiat figure displays cumulative value of staked BSPT with all earned BST rewards. BST figure shows amount of earned tokens only.',
    stopped: false,
  },
  {
    id: config.bstEthPoolAddress,
    asset1Name: 'BST',
    asset2Name: 'ETH',
    color: '#80ddd3',
    name: 'BST:ETH',
    poolType: 'uniswap',
    portfolioName: 'Liquidity pools',
    stopped: true,
  },
  {
    id: config.bstDaiPoolAddress,
    asset1Name: 'BST',
    asset2Name: 'DAI',
    color: '#80ddd3',
    name: 'BST:DAI',
    poolType: 'uniswap',
    stopped: true,
    // portfolioName: 'Liquidity pools',
  },
  {
    id: config.pointPoolAddress,
    asset1Name: 'BSPT',
    asset2Name: 'sPOINT',
    color: '#a8e8e1',
    name: 'BSPT:World',
    poolType: 'oceanpoint',
    portfolioName: 'Stable pools',
    stopped: true,
  },
  {
    id: config.pointDaiPoolAddress,
    asset1Name: 'POINT',
    asset2Name: 'DAI',
    color: '#cef2ee',
    name: 'POINT:DAI',
    poolType: 'balancer',
    stopped: true,
  },
] as Array<IPoolConfig>;

export { POOL_DETAILS };
