


































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import FullSizeCard from '@/components/common/cards/FullSizeCard.vue';
import Select from '@/components/select/Select.vue';
import { PoolInformation, TokenInformation } from '@/types';

const AmountForm = () => import('@/components/form/AmountForm.vue');
const ConnectWalletCard = () => import('@/components/common/cards/ConnectWalletCard.vue');

export default Vue.extend({
  name: 'StakingRow',
  components: {
    Select,
    ConnectWalletCard,
    FullSizeCard,
    AmountForm,
  },
  props: {
    pool: {
      type: Object as () => PoolInformation,
      required: true,
    },
    className: String,
    deprecated: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tab: null,
    expandArea: false,
    allowanceSet: false,
    breakpointSmAndUp: false,
    showTradeDialog: false,
    isDeposit: false,
    selectedProperty: { id: '', symbol: '' },
    showOnlyOwnedPropertyTokens: false,
    hasFetchedAvailableTokens: false,
  }),
  computed: {
    ...mapState('wallet', ['isConnected', 'address']),
    ...mapGetters('token', ['bsptTokens']),
    apy(): number {
      if (this.pool.config.isBSPTPool) return this.pool.bsptApy10;
      return this.pool.apy10;
    },
    loading(): boolean {
      return (
        this.pool.isLoadingBalance ||
        this.pool.isLoadingUserBalance ||
        this.pool.isLoadingStats ||
        this.pool.isLoadingUsdPrice
      );
    },
    collapsable(): boolean {
      return this.pool.isActive && this.breakpointSmAndUp;
    },
    displayedProperties(): Array<TokenInformation> {
      if (this.showOnlyOwnedPropertyTokens) {
        const tokensToDisplay = this.bsptTokens.filter(
          (token: TokenInformation) => token.userOwnsToken
        );

        // IMPORTANT
        // If owned tokens are 0, we need a way to show a nice message
        return tokensToDisplay;
      }
      return this.bsptTokens;
    },
    assetPoolDepositInfo(): { stakedTokens: number; lockedUntil: number | null } {
      if (this.selectedProperty && this.selectedProperty.id) {
        const userDeposit = this.pool.userDeposits.filter(
          (deposit) => deposit.tokenId === this.selectedProperty.id.toString()
        );
        if (userDeposit[0]) {
          return { stakedTokens: userDeposit[0].sAmount, lockedUntil: userDeposit[0].lockedUntil };
        }
      }

      // In case there's no deposits for this property, we set the lockedUntil
      // date to some time in the year of 1999 :D
      return { stakedTokens: 0, lockedUntil: 927611583 };
    },
    // This computed prop assures the watcher that we're in a bspt pool and that
    // we have the wallet and the deposits that the user already made before
    // appending the tokens in the user's wallet as well to show all the
    // relevant/interactable properties to the user
    startFetchingAvailableTokens(): boolean {
      if (
        this.address &&
        this.pool.hasLoadedUserBalance &&
        this.pool.config.isBSPTPool &&
        this.hasFetchedAvailableTokens === false
      ) {
        return true;
      }
      return false;
    },
    showNoTokensOwnedMsg(): boolean {
      const ownedTokenExists = this.bsptTokens.find(
        (token: TokenInformation) => token.userOwnsToken === true
      );

      if (!ownedTokenExists) {
        return true;
      }
      return false;
    },
  },
  created() {
    window.addEventListener('resize', this.onWindowResize);
    this.updateBreakpointSmAndUp();
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  mounted() {
    this.selectedProperty = this.bsptTokens[0];
  },
  methods: {
    ...mapActions('token', ['getBSPTTokenOwnership']),
    onWindowResize() {
      this.showTradeDialog = false;
      this.expandArea = false;
      this.updateBreakpointSmAndUp();
    },
    updateBreakpointSmAndUp() {
      this.breakpointSmAndUp = window.innerWidth > 600;
    },
    showDepositDialog() {
      this.isDeposit = true;
      this.showTradeDialog = true;
    },
    showWithdrawDialog() {
      this.isDeposit = false;
      this.showTradeDialog = true;
    },
    handleChangedSelection(value: any) {
      this.selectedProperty = value;
    },
  },
  watch: {
    displayedProperties(newValue) {
      if (newValue.length > 0 && this.showOnlyOwnedPropertyTokens === true) {
        this.selectedProperty = this.displayedProperties[0];
      }
    },
    startFetchingAvailableTokens(newValue) {
      if (newValue === true) {
        const depositedTokens =
          this.pool.userDeposits.length > 0
            ? this.pool.userDeposits.map((userDeposit) => userDeposit.tokenId)
            : [];

        this.getBSPTTokenOwnership({ depositedTokens, wallet: this.address });
        this.hasFetchedAvailableTokens = true;
      }
    },
    // Reset the variable which tells us that tokens were fetched after
    // we detect a wallet reconnect
    address(newValue) {
      if (this.pool.config.isBSPTPool && newValue) {
        this.hasFetchedAvailableTokens = false;
        this.showOnlyOwnedPropertyTokens = true;
      }

      if (!newValue) {
        this.showOnlyOwnedPropertyTokens = false;
      }
    },
    bsptTokens() {
      this.hasFetchedAvailableTokens = false;
    },
  },
});
