































import Vue from 'vue';
import {
  isBusinessDay,
  createChart,
  IChartApi,
  ISeriesApi,
  PriceScaleMode,
  PriceFormatBuiltIn,
} from 'lightweight-charts';
import { mapGetters, mapState } from 'vuex';
import { ResizeObserver } from '@juggle/resize-observer';
import dayjs from 'dayjs';
import { config } from '@/config';
import SectionHeading from '../common/typography/SectionHeading.vue';
import FullSizeCard from '../common/cards/FullSizeCard.vue';
import ProgressOverlay from '@/../src/components/overlay/ProgressOverlay.vue';
import { IPoolStat, IBSPTPoolStat, TokenInformation } from '@/types';

const InfoBlock = () =>
  import(/* webpackChunkName: "info-block" */ '@/components/common/cards/InfoBlock.vue');

interface ChartDataItem {
  time: string;
  value: number;
}

type ChartData = Array<ChartDataItem>;
type AssetPoolData = Array<ChartDataItem>;

interface ComponentData {
  currency: string;
  chartInstance: IChartApi;
  stakedBST: ISeriesApi<'Area'>;
  assetPool: ISeriesApi<'Area'>;
  toolTipLeft: number;
}

const chartSettings = {
  width: 750,
  height: 400,
  handleScroll: false,
  handleScale: false,
  leftPriceScale: {
    scaleMargins: {
      top: 0.2,
      bottom: 0.2,
    },
    visible: true,
    borderVisible: false,
  },
  rightPriceScale: {
    mode: PriceScaleMode.Percentage,
    visible: false,
  },
  timeScale: {
    borderVisible: false,
  },
  grid: {
    horzLines: {
      color: '#eee',
    },
    vertLines: {
      color: '#ffffff',
    },
  },
  crosshair: {
    horzLine: {
      visible: false,
      labelVisible: false,
    },
    vertLine: {
      visible: true,
      style: 0,
      width: 2,
      color: 'rgba(32, 38, 46, 0.1)',
      labelVisible: false,
    },
  },
  localization: {
    locale: 'en-US',
  },
};

export default Vue.extend({
  name: 'OceanpointStats',
  components: {
    InfoBlock,
    SectionHeading,
    FullSizeCard,
    ProgressOverlay,
  },
  data: () =>
    ({
      currency: '$',
      chartInstance: {},
      stakedBST: {},
      toolTipLeft: 0,
    } as ComponentData),
  computed: {
    ...mapGetters('pool', ['pool', 'totalValueLocked', 'isLoadingToalValueLocked']),
    ...mapGetters('token', ['token']),
    price(): number {
      return this.token(config.bstTokenContractAddress).price;
    },
    governancePoolStats(): Array<IPoolStat> {
      return this.pool(config.stakingContractAddress).stats;
    },
    assetPoolStats(): Array<IBSPTPoolStat> {
      /* code to simulate one missing day (the last day) 

        const newArr = this.pool(config.bsptPoolAddress).bsptStats;
        newArr.shift();
        return newArr;
      */
      if (this.pool(config.bsptPoolAddress).bsptStats.length > 0) {
        return this.pool(config.bsptPoolAddress).bsptStats;
      }
      return [];
    },
    /*
      Takes assetPoolStats and adds them to the governance Stats to calculate culmunative values
    */
    assetPoolData(): AssetPoolData {
      const data: AssetPoolData = [];
      let newData: AssetPoolData = [];
      if (this.assetPoolStats.length > 0 && this.price && this.chartData.length > 0) {
        // for each BSPT check if there is an asset pool with the same date and add their values
        for (let z = 0; z < this.assetPoolStats.length; z++) {
          const stat = this.assetPoolStats[z];

          for (let y = 0; y < this.chartData.length; y++) {
            const thChartD = this.chartData[y];

            if (stat.date === thChartD.time) {
              //  do the addition
              data.push({
                // date is the same
                time: stat.date,
                value: stat.totalValue + thChartD.value,
              });
            }
          }

          if (z === this.assetPoolStats.length - 1) {
            newData = this.fillEmptyDates(data);
          }
        }
      }
      //  add a zero value entry in beginning
      if (newData.length > 0) {
        const toAdd = { ...newData[0] };
        toAdd.time = dayjs(toAdd.time).subtract(1, 'day').format('YYYY-MM-DD');
        toAdd.value = 0;
        newData.unshift(toAdd);
      }

      return newData;
    },
    chartData(): ChartData {
      const data: ChartData = [];
      let newData: ChartData = [];
      if (this.governancePoolStats.length > 0 && this.price) {
        for (let z = 0; z < this.governancePoolStats.length; z++) {
          const stat = this.governancePoolStats[z];
          data.push({
            time: stat.date,
            value: stat.currentAmount * this.price,
          });
          if (z === this.governancePoolStats.length - 1) {
            newData = this.fillEmptyDates(data);
          }
        }
      }
      //  add a zero value entry in beginning
      if (newData.length > 0) {
        const toAdd = { ...newData[0] };
        toAdd.time = dayjs(toAdd.time).subtract(1, 'day').format('YYYY-MM-DD');
        toAdd.value = 0;
        newData.unshift(toAdd);
      }

      return newData;
    },
    poolCategories(): Array<unknown> {
      return [
        {
          name: 'All pools',
          color: 'rgba(86,189,174, 1)',
        },
        {
          name: 'Governance pool',
          color: '#00c0aa',
        },
        {
          name: 'Asset pools',
          color: 'rgba(86,189,174, 0.4)',
        },
      ];
    },
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      this.chartInstance = createChart(
        this.$refs.chartContainer as HTMLElement,
        chartSettings as any
      );
    },
    updateData() {
      // find the max value from this.chartData
      const maxFromChartData = Math.max(...this.chartData.map((d) => d.value));
      const maxFromAssetPool = Math.max(...this.assetPoolData.map((d) => d.value));

      const maxValue = Math.max(maxFromChartData, maxFromAssetPool);
      const minValue = maxValue / 2.5;

      const priceRange = {
        priceRange: {
          minValue,
          maxValue,
          autoScale: false,
        },
      };

      const priceFormat = {
        type: 'price',
        precision: 1,
      } as PriceFormatBuiltIn;

      // draw the asetPool Line
      this.assetPool = this.chartInstance.addAreaSeries({
        topColor: '#60d2c2',
        bottomColor: '#60d2c2',
        lineColor: '#60d2c2',
        lineWidth: 2,

        autoscaleInfoProvider: () => priceRange,
        priceFormat,
      });

      // draw the stakedBST line
      this.stakedBST = this.chartInstance.addAreaSeries({
        topColor: '#00c0aa',
        bottomColor: '#00c0aa',
        lineColor: '#00c0aa',

        autoscaleInfoProvider: () => priceRange,
        priceFormat,
      });

      // add the staked BST Data
      this.stakedBST.setData(this.chartData as any);

      // add the asset pool data
      this.assetPool.setData(this.assetPoolData as any);

      new ResizeObserver((entries) => {
        if (entries.length === 0 || entries[0].target !== this.$refs.chartContainer) {
          return;
        }
        const newRect = entries[0].contentRect;
        this.chartInstance.applyOptions({ height: newRect.height, width: newRect.width });
      }).observe(this.$refs.chartContainer as Element);

      this.updateToolTip();
      this.chartInstance.timeScale().fitContent();
    },
    updateToolTip() {
      const toolTipWidth = 96;
      const toolTipMargin = 15;
      const priceScaleWidth = 50;

      const toolTip = this.$refs.floatingToolTip as HTMLElement;
      this.chartInstance.subscribeCrosshairMove((param: any) => {
        if (
          !param.time ||
          param.point.x < 0 ||
          param.point.x > chartSettings.width ||
          param.point.y < 0 ||
          param.point.y > chartSettings.height
        ) {
          toolTip.style.display = 'none';
          return;
        }
        const dateStr = isBusinessDay(param.time)
          ? this.businessDayToString(param.time)
          : new Date(param.time * 1000).toLocaleDateString();

        toolTip.style.display = 'flex';
        const totalStakedBST = param.seriesPrices.get(this.stakedBST);
        const totalStakedAssetPool = param.seriesPrices.get(this.assetPool);

        const totalStakedBSTPrice =
          totalStakedBST !== null ? Math.round((totalStakedBST * 100) / 100) : 0;

        let totalStakedAssetPoolPrice;
        if (totalStakedAssetPool !== null && totalStakedAssetPool !== undefined) {
          totalStakedAssetPoolPrice = Math.round((totalStakedAssetPool * 100) / 100);

          if (totalStakedAssetPoolPrice > totalStakedBSTPrice) {
            totalStakedAssetPoolPrice -= totalStakedBSTPrice;
          }
        } else {
          totalStakedAssetPoolPrice = 0;
        }

        const totalStakedBSTHTML = `<label><span style="color: #00c0aa">⬤</span> Total BST</label> <div style="font-size: 16px; font-weight: bold; margin: 4px 0px; color: #20262E"> ${totalStakedBSTPrice.toLocaleString(
          'en',
          {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }
        )}
          </div>`;

        const totalStakedAssetPoolHTML = `<label><span style="color: #60d2c2;">⬤</span> Total Asset Pool</label> <div style="font-size: 16px; font-weight: bold; margin: 4px 0px; color: #20262E">${totalStakedAssetPoolPrice.toLocaleString(
          'en',
          {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }
        )}
          </div>`;
        const dateFooterHTML = `<time>${dateStr}</time>`;
        toolTip.innerHTML = dateFooterHTML + totalStakedAssetPoolHTML + totalStakedBSTHTML;

        let left = param.point.x;

        if (left > chartSettings.width - toolTipWidth - toolTipMargin) {
          left = chartSettings.width - toolTipWidth;
        } else if (left < toolTipWidth / 2) {
          left = priceScaleWidth;
        }
        this.toolTipLeft = left;
      });
    },
    businessDayToString(businessDay: any) {
      return new Date(
        Date.UTC(businessDay.year, businessDay.month - 1, businessDay.day, 0, 0, 0)
      ).toLocaleDateString();
    },
    addDaysToDate(toDate: Date, days: number) {
      const date = new Date(toDate);
      date.setDate(date.getDate() + days);
      return date;
    },
    fillEmptyDates(toFillData: any) {
      const dateFormat = 'YYYY-MM-DD';
      //  add date of today with a value from the latest index
      const todaysDate = dayjs(new Date(), dateFormat);
      const lastDayData = toFillData[0];
      const latestDate = dayjs(lastDayData.time, dateFormat);
      const daysBetween = todaysDate.diff(latestDate, 'day');
      if (daysBetween > 0) {
        const today = {
          time: dayjs(new Date(), dateFormat).format(dateFormat),
          value: lastDayData.value,
        };
        toFillData.push(today);
      }

      const newData = [];
      for (let c = 0; c < toFillData.length; c++) {
        let futureDate: dayjs.Dayjs;
        let currDate: dayjs.Dayjs;
        let futureValue: number;
        let currValue: number;

        if (c > 0) {
          currDate = dayjs(toFillData[c].time, dateFormat);
          currValue = toFillData[c].value;

          futureDate = dayjs(toFillData[c - 1].time, dateFormat);
          futureValue = toFillData[c - 1].value;

          const dateDiff = futureDate.diff(currDate, 'days');
          if (dateDiff > 1) {
            for (let x = 1; x < dateDiff; x++) {
              const newD = dayjs(currDate).add(x, 'days').format(dateFormat);
              newData.push({
                time: newD,
                value: currValue,
              });
            }
          }
        }
      }

      function sortFunction(a: any, b: any) {
        const dateA = new Date(a.time).getTime();
        const dateB = new Date(b.time).getTime();
        return dateA > dateB ? 1 : -1;
      }

      const finalReturn = [...toFillData, ...newData];
      finalReturn.sort(sortFunction);
      return finalReturn;
    },
  },

  watch: {
    chartData() {
      this.updateData();
    },
  },
});
