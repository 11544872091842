interface IPoolConfig {
  id: string;
  asset1Address: string;
  asset1Name: string;
  asset2Address: string;
  asset2Name: string;
  color: string;
  isGovernancePool: boolean;
  isBSPTPool: boolean;
  name: string;
  poolType: string;
  portfolioName: string;
  stopped: boolean;
  info: string;
}

class PoolConfig implements IPoolConfig {
  private _id: string;

  asset1Address: string;

  asset1Name: string;

  asset2Address: string;

  asset2Name: string;

  color: string;

  isGovernancePool: boolean;

  isBSPTPool: boolean;

  name: string;

  poolType: string;

  portfolioName: string;

  stopped: boolean;

  info: string;

  constructor(
    id: string,
    asset1Address: string,
    asset1Name: string,
    asset2Address: string,
    asset2Name: string,
    color: string,
    isGovernancePool: boolean,
    isBSPTPool: boolean,
    name: string,
    poolType: string,
    portfolioName: string,
    stopped: boolean,
    info: string
  ) {
    this._id = id.toLocaleLowerCase();
    this.asset1Name = asset1Name;
    this.color = color;
    this.name = name;
    this.poolType = poolType;
    // Some values are optional and might be undefined in constructor
    this.asset1Address = asset1Address ?? '';
    this.asset2Address = asset2Address ?? '';
    this.asset2Name = asset2Name ?? '';
    this.isGovernancePool = isGovernancePool ?? false;
    this.isBSPTPool = isBSPTPool ?? false;
    this.portfolioName = portfolioName ?? '';
    this.stopped = stopped ?? false;
    this.info = info ?? '';
  }

  get id(): string {
    return this._id;
  }
}

export { IPoolConfig, PoolConfig };
