






























































import Vue from 'vue';
import blockies from 'ethereum-blockies';
import { TokenInformation } from '@/types';

export default Vue.extend({
  name: 'Select',
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    blockie(tokenAddress: string): string {
      const canvas = blockies.create({
        seed: tokenAddress,
      });
      return canvas.toDataURL();
    },
    handleChange(newValue: TokenInformation) {
      this.$emit('changed', newValue);
    },
  },
});
