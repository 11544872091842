import { BigNumber } from 'bignumber.js';

function getBigNumber(valuation: string | number | BigNumber) {
  if (valuation) return valuation instanceof BigNumber ? valuation : new BigNumber(valuation);
  return new BigNumber(0);
}

export function fromWei(valuation: string | number | BigNumber, decimals = 18): number {
  return getBigNumber(valuation)
    .dividedBy(10 ** decimals)
    .toNumber();
}

export function toWei(valuation: string | number | BigNumber, decimals = 18): number {
  return getBigNumber(valuation)
    .multipliedBy(10 ** decimals)
    .toNumber();
}

export function displayAddress(address: string, length = 6): string {
  if (!address) return '';
  const addressLength = address.length;

  const firstPart = address.substring(0, length);
  const lastPart = address.substring(addressLength - length, addressLength);
  return `${firstPart}...${lastPart}`;
}
