



















































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { config } from '@/config';
import { PoolInformation } from '@/types';
import StakingRow from './rows/StakingRow.vue';
import MarketStats from './stats/MarketStats.vue';
import OceanpointStats from './stats/OceanpointStats.vue';
import PersonalStats from './stats/PersonalStats.vue';

export default Vue.extend({
  name: 'Dashboard',
  components: {
    StakingRow,
    MarketStats,
    OceanpointStats,
    PersonalStats,
  },

  data: () => ({}),
  computed: {
    ...mapState('wallet', ['address']),
    ...mapGetters('pool', ['pool']),
    governancePool(): PoolInformation {
      return this.pool(config.stakingContractAddress);
    },
    bsptPool(): PoolInformation {
      return this.pool(config.bsptPoolAddress);
    },
    bstEthPool(): PoolInformation {
      return this.pool(config.bstEthPoolAddress);
    },
    bstDaiPool(): PoolInformation {
      return this.pool(config.bstDaiPoolAddress);
    },
    pointPool(): PoolInformation {
      return this.pool(config.pointPoolAddress);
    },
    pointDaiPool(): PoolInformation {
      return this.pool(config.pointDaiPoolAddress);
    },
  },
  mounted() {
    const poolId = config.stakingContractAddress;
    this.updatePoolBalance({ poolId });
    this.updatePoolStats({ poolId });

    // BSPT pool
    this.updatePoolBalance({ poolId: config.bsptPoolAddress });
    this.updateBSPTPoolStats({ poolId: config.bsptPoolAddress });
    this.updateBSPTTokens();
  },
  methods: {
    ...mapActions('pool', [
      'updatePoolBalance',
      'updatePoolStats',
      'updateBSPTPoolStats',
      'updateUserPoolBalance',
    ]),
    ...mapActions('token', ['updateBSPTTokens']),
  },
  watch: {
    address(newAddress) {
      this.updateUserPoolBalance({
        poolId: config.stakingContractAddress,
        userId: newAddress.toLowerCase(),
      });
      this.updateUserPoolBalance({
        poolId: config.bsptPoolAddress,
        userId: newAddress.toLowerCase(),
      });
    },
  },
});
