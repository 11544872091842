import { toHex } from 'web3-utils';

export interface IConfig {
  chainId: number;
  stakingContractAddress: string;
  bstTokenContractAddress: string;
  infuraId: string;
  oceanpointSubgraph: string;
  bsptPoolAddress: string;

  // This fields have to be there in all environments to get the supply and price
  uniswapSubgraph: string;
  bstTokenMainnetAddress: string;
  bstEthPoolAddress: string;
  bstDaiPoolAddress: string;
  pointPoolAddress: string;
  pointDaiPoolAddress: string;
}

function parseEnvironment(): IConfig {
  const bstTokenContractAddress = process.env.VUE_APP_BSTTOKEN_CONTRACT_ADDRESS;
  const bstTokenMainnetAddress = process.env.VUE_APP_BST_TOKEN_MAINNET_ADDRESS;
  const chainId = process.env.VUE_APP_CHAIN_ID;
  const infuraId = process.env.VUE_APP_INFURA_ID;
  const oceanpointSubgraph = process.env.VUE_APP_OCEANPOINT_SUBGRAPH;
  const stakingContractAddress = process.env.VUE_APP_STAKING_CONTRACT_ADDRESS;
  const uniswapSubgraph = process.env.VUE_APP_UNISWAP_SUBGRAPH;
  const bsptPoolAddress = process.env.VUE_APP_BSPT_POOL;

  if (
    !bstTokenContractAddress ||
    !bstTokenMainnetAddress ||
    !chainId ||
    !infuraId ||
    !oceanpointSubgraph ||
    !stakingContractAddress ||
    !uniswapSubgraph ||
    !bsptPoolAddress
  ) {
    const undefinedFields = Array<string>();

    if (!bstTokenContractAddress) undefinedFields.push('VUE_APP_BST_TOKEN_CONTRACT_ADDRESS');
    if (!bstTokenMainnetAddress) undefinedFields.push('VUE_APP_BST_TOKEN_MAINNET_ADDRESS');
    if (!chainId) undefinedFields.push('VUE_APP_CHAIN_ID');
    if (!infuraId) undefinedFields.push('VUE_APP_INFURA_ID');
    if (!oceanpointSubgraph) undefinedFields.push('VUE_APP_OCEANPOINT_SUBGRAPH');
    if (!stakingContractAddress) undefinedFields.push('VUE_APP_STAKING_CONTRACT_ADDRESS');
    if (!uniswapSubgraph) undefinedFields.push('VUE_APP_UNISWAP_SUBGRAPH');
    if (!bsptPoolAddress) undefinedFields.push('VUE_APP_BSPT_POOL');

    throw new Error(
      `The following environment variables are required: ${undefinedFields.join(', ')}`
    );
  }

  return {
    bstTokenContractAddress,
    bstTokenMainnetAddress,
    chainId: parseInt(chainId, 10),
    infuraId,
    oceanpointSubgraph,
    stakingContractAddress,
    uniswapSubgraph,
    bsptPoolAddress,

    // Temp pool addresses
    bstEthPoolAddress: '1',
    bstDaiPoolAddress: '2',
    pointPoolAddress: '3',
    pointDaiPoolAddress: '4',
  };
}

class Config implements IConfig {
  chainId: number;

  infuraId: string;

  oceanpointSubgraph: string;

  // This fields have to be there in all environments to get the supply and price
  uniswapSubgraph: string;

  /* Addresses should be returned with toLowerCase */
  private _bstTokenContractAddress: string;

  private _bstTokenMainnetAddress: string;

  private _stakingContractAddress: string;

  private _bsptPoolAddress: string;

  // Temp pools for display only
  private _bstDaiPoolAddress: string;

  private _bstEthPoolAddress: string;

  private _pointDaiPoolAddress: string;

  private _pointPoolAddress: string;

  constructor() {
    const {
      bstTokenContractAddress,
      bstTokenMainnetAddress,
      chainId,
      infuraId,
      oceanpointSubgraph,
      stakingContractAddress,
      uniswapSubgraph,
      bsptPoolAddress,
      bstDaiPoolAddress,
      bstEthPoolAddress,
      pointDaiPoolAddress,
      pointPoolAddress,
    } = parseEnvironment();

    this._bstTokenContractAddress = bstTokenContractAddress;
    this._bstTokenMainnetAddress = bstTokenMainnetAddress;
    this._bsptPoolAddress = bsptPoolAddress;
    this._stakingContractAddress = stakingContractAddress;
    this.chainId = chainId;
    this.infuraId = infuraId;
    this.oceanpointSubgraph = oceanpointSubgraph;
    this.uniswapSubgraph = uniswapSubgraph;

    // Temp pools for display only
    this._bstDaiPoolAddress = bstDaiPoolAddress;
    this._bstEthPoolAddress = bstEthPoolAddress;
    this._pointDaiPoolAddress = pointDaiPoolAddress;
    this._pointPoolAddress = pointPoolAddress;
  }

  get bstTokenContractAddress(): string {
    return this._bstTokenContractAddress.toLowerCase();
  }

  get bstTokenMainnetAddress(): string {
    return this._bstTokenMainnetAddress.toLowerCase();
  }

  get chainIdHex(): string {
    return toHex(this.chainId);
  }

  get stakingContractAddress(): string {
    return this._stakingContractAddress.toLowerCase();
  }

  get bstDaiPoolAddress(): string {
    return this._bstDaiPoolAddress.toLowerCase();
  }

  get bstEthPoolAddress(): string {
    return this._bstEthPoolAddress.toLowerCase();
  }

  get pointDaiPoolAddress(): string {
    return this._pointDaiPoolAddress.toLowerCase();
  }

  get pointPoolAddress(): string {
    return this._pointPoolAddress.toLowerCase();
  }

  get bsptPoolAddress(): string {
    return this._bsptPoolAddress.toLowerCase();
  }
}

const config = Object.freeze(new Config());

export { config };
