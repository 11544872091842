import { POOL_DETAILS } from '@/config';
import { PoolConfig } from '@/types/pool/poolConfig';

const getPoolConfig = (poolId: string): PoolConfig => {
  const tempPoolDetails = POOL_DETAILS.find((tempPool) => tempPool.id === poolId);
  if (!tempPoolDetails) throw new Error(`Pool config for id: ${poolId} doesn't exsits`);
  return new PoolConfig(
    tempPoolDetails.id,
    tempPoolDetails.asset1Address,
    tempPoolDetails.asset1Name,
    tempPoolDetails.asset2Address,
    tempPoolDetails.asset2Name,
    tempPoolDetails.color,
    tempPoolDetails.isGovernancePool,
    tempPoolDetails.isBSPTPool,
    tempPoolDetails.name,
    tempPoolDetails.poolType,
    tempPoolDetails.portfolioName,
    tempPoolDetails.stopped,
    tempPoolDetails.info
  );
};

export { getPoolConfig };
